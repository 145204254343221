import ContactUspage from "components/pages/contactUs"
import HeaderTwo from "components/shared/HeaderTwo"
import React from "react"
import Footer from "components/shared/Footer"
import { Helmet } from "react-helmet"
const Contact = () => {
	return (
		<>
			<Helmet>
				<title>MercurySols Technologies | ContactUs</title>
			</Helmet>
			<HeaderTwo />
			<ContactUspage />
			<Footer />
		</>
	)
}
export default Contact
